<div>
  <div style="display: block">
    <div class="col-sm-12">
      <div class="col-sm-12 light-blur-img" style="height: 40vh; width: 100%">
        <div id="featureimage" style="text-align:center">
          <img src="../assets/mainLogo.png" style="height: 40vh" />
        </div>
      </div>
    </div>
    <div class="col-sm-12" style="text-align: center" [hidden]="!mobile">
     Full-service bookkeeping and consulting for small businesses. Learn what the numbers mean and how to increase profitability. Use real time data to forecast what the future looks like!
    </div>
    <div class="col-sm-12" [hidden]="mobile">
      <div class="col-sm-12 four-blocks-top">
        <div class="col-sm-3 block-top block-one-top">
          <div class="four-blocks-top-title">
            Full Service Bookkeeping
          </div>
        </div>
        <div class="col-sm-3 block-top block-two-top">
          <div class="four-blocks-top-title">
            Consulting
          </div>
        </div>
        <div class="col-sm-3 block-top block-three-top">
          <div class="four-blocks-top-title">
            Set Up For QuickBooks&#174;
          </div>
        </div>
        <div class="col-sm-3 block-top block-four-top block-four-border">
          <div class="four-blocks-top-title">
            Training For QuickBooks&#174;
          </div>
        </div>
      </div>
      <div class="col-sm-12 four-blocks">
        <div class="col-sm-3 four-blocks-text block-one-border">
          <img src="../assets/receiptMachine.png" height="150" />
        </div>
        <div class="col-sm-3 four-blocks-text block-two-border">
          <img src="../assets/upTrendChart.png" height="150" />
        </div>
        <div class="col-sm-3 four-blocks-text block-three-border">
          <img src="../assets/moneyStack.png" height="150" />
        </div>
        <div class="col-sm-3 four-blocks-text block-four-border">
          <img src="../assets/receiptMachine.png" height="150" />
        </div>
      </div>
      <div class="col-sm-12 four-blocks">
        <div class="col-sm-3 four-blocks-text block-one-border">
          <div class="four-blocks-text-container">
            <h3>
              Do you feel like there aren't enough hours in the the day? Are you bogged down with entering receipts and trying to reconcile accounts?
              Let us take care of that for you!
            </h3>
          </div>
          <button class="learn-button" (click)="navigateToAppointment()">Learn More</button>
        </div>
        <div class="col-sm-3 four-blocks-text block-two-border">
          <div class="four-blocks-text-container">
            <h3>
              Unsure of what to do with the numbers once you have them?
            </h3>
          </div>
          <button class="learn-button" (click)="navigateToAppointment()">Learn More</button>
        </div>
        <div class="col-sm-3 four-blocks-text block-three-border">
          <div class="four-blocks-text-container">
            <h3>
              Start off on the right foot with a QuickBooks&#174; that's unique to your needs! Having an accounting software that isn't set up properly will produce inaccurate data.
            </h3>
          </div>
          <button class="learn-button" (click)="navigateToAppointment()">Learn More</button>
        </div>
        <div class="col-sm-3 four-blocks-text block-four-border">
          <div class="four-blocks-text-container">
            <h3>
              Trying to figure out the system while you go? Have an employee that needs guidance? Feel like a pro in no time!
            </h3>
          </div>
          <button class="learn-button" (click)="navigateToAppointment()">Learn More</button>
        </div>
      </div>
    </div>
    <div class="col-sm-12 bottom-banner">
      <div class="col-sm-12" style="float: right;">
        <button class="bottom-button" style="margin-top: 3vh;" (click)="navigateToAppointment()">Appointment</button>
        <button class="bottom-button" (click)="navigateToAppointment()"> Upload</button>
        <button class="bottom-button" (click)="navigateToAppointment()">About Us</button>
      </div>
    </div>
  </div>
</div>
