import { Component } from '@angular/core';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css']
})
/** about component*/
export class AboutComponent {
    /** about ctor */
    constructor() {

    }
}