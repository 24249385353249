import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent {

  mobile: boolean = false;

  constructor(private route: Router) {
  }

  ngOnInit() {
    if (window.screen.width < 1366) { // 768px portrait
      this.mobile = true;
    }
  }

  navigateToContact() {
    this.route.navigate(['/contact']);
  }

  navigateToAppointment() {
    this.route.navigate(['/appointment']);
  }
}
