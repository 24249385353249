<head>
  <!-- Global site tag (gtag.js) - Google Analytics -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=UA-183139241-1"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'UA-183139241-1');
  </script>
</head>
<div>
  <mat-toolbar class="stickyBar">
    <span style="padding-left: 2%">
      <img src="../assets/mainLogoTrimmed.png" class="remove-outline" alt="Account For It All" width="50" height="50" routerLink="/home">
    </span>
    <span class="toolbar-spacer"></span>
    <span style="padding-right: 2%">
      <button class="toolbar-icon" routerLink="/home">
        Home
      </button>
      <button class="toolbar-icon" routerLink="/appointment">
        Schedule Appointment
      </button>
      <button class="toolbar-icon" routerLink="/contact">
        Contact Us
      </button>
    </span>
  </mat-toolbar>
  <div class="main-container">
    <router-outlet></router-outlet>
  </div>
</div>
